@import url(https://fonts.googleapis.com/css?family=Press + Start + 2p);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table.main td.MuiTableCell-root {
  padding: 4px 8px;
}

table.main td.MuiTableCell-root.no-items {
  padding: 16px;
}

.MuiAlert-standardError {
  margin-bottom: 10px;
}

.MuiCardHeader-root.card-header {
  padding: 2px;
  background-color: #1aad8d;
}

.MuiCardHeader-root.card-header span.MuiCardHeader-title {
  margin-left: 20px;
  font-size: 0.9em;
  text-align: left;
  color: #fff;
}

.bottom-input-block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 30px;
  position: fixed;
}

.bottom-input-block .MuiFormControl-root {
  margin-right: 10px;
}

.live-stream-joystick {
  position: relative;
  max-width: 960px;
  margin: 0 auto;
}

.live-stream-joystick .right-menu-buttons {
  position: absolute;
  right: 0px;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}

.live-stream-joystick .right-menu-buttons .MuiButtonBase-root {
  margin-top: 30px;
}

.live-stream-joystick .right-menu-buttons .MuiButtonBase-root.paused {
  margin-top: 0px;
}

.live-stream-joystick
  .right-menu-buttons
  .MuiButtonBase-root.paused
  .MuiSvgIcon-root {
  color: rgb(245, 75, 75);
}

.live-stream-joystick .right-menu-buttons .sub-label {
  font-size: 10px;
  color: rgb(245, 75, 75);
  width: 100%;
  text-align: center;
  margin-top: 5px;
  font-weight: bolder;
  margin: 14px 0 5px;
}

.live-stream-joystick .right-menu-buttons .MuiButton-label .MuiSvgIcon-root {
  font-size: 3rem;
}

.live-stream-video {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 960px;
}

/*Font Imports*/

/*Keyframes*/
@-webkit-keyframes messagefade {
  0% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  99% {
    opacity: 0;
    top: 0;
  }
  100% {
    opacity: 0;
    top: -51px;
  }
}

@keyframes messagefade {
  0% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  99% {
    opacity: 0;
    top: 0;
  }
  100% {
    opacity: 0;
    top: -51px;
  }
}

@-webkit-keyframes helptip {
  0% {
    margin: 0 0 0 0px;
  }
  10% {
    margin: 0 0 0 -15px;
  }
  20% {
    margin: 0 0 0 0px;
  }
  30% {
    margin: 0 0 0 -15px;
  }
  40% {
    margin: 0 0 0 0px;
  }
  50% {
    margin: 0 0 0 -15px;
  }
  60% {
    margin: 0 0 0 0px;
  }
  70% {
    margin: 0 0 0 -15px;
  }
  80% {
    margin: 0 0 0 0px;
  }
  90% {
    margin: 0 0 0 -15px;
    visibility: visible;
    opacity: 1;
  }
  100% {
    margin: 0 0 0 0px;
    visibility: hidden;
    opacity: 0;
  }
}

@keyframes helptip {
  0% {
    margin: 0 0 0 0px;
  }
  10% {
    margin: 0 0 0 -15px;
  }
  20% {
    margin: 0 0 0 0px;
  }
  30% {
    margin: 0 0 0 -15px;
  }
  40% {
    margin: 0 0 0 0px;
  }
  50% {
    margin: 0 0 0 -15px;
  }
  60% {
    margin: 0 0 0 0px;
  }
  70% {
    margin: 0 0 0 -15px;
  }
  80% {
    margin: 0 0 0 0px;
  }
  90% {
    margin: 0 0 0 -15px;
    visibility: visible;
    opacity: 1;
  }
  100% {
    margin: 0 0 0 0px;
    visibility: hidden;
    opacity: 0;
  }
}

@-webkit-keyframes msg-animation {
  80% {
    opacity: 1;
  }
  99.99% {
    opacity: 0;
    height: auto;
    visibility: visible;
    margin-bottom: 5px;
    padding: 5px;
  }
  100% {
    height: 0px;
    margin: 0px;
    padding: 0px;
    visibility: hidden;
  }
}

@keyframes msg-animation {
  80% {
    opacity: 1;
  }
  99.99% {
    opacity: 0;
    height: auto;
    visibility: visible;
    margin-bottom: 5px;
    padding: 5px;
  }
  100% {
    height: 0px;
    margin: 0px;
    padding: 0px;
    visibility: hidden;
  }
}

/* Didn't work, used transitions instead
@keyframes modal-flow {
    from {visibility: hidden;top:-100px;opacity: 0;}
    to {visitbility: visible;top:0px;opacity:1;}
}
@-moz-keyframes modal-flow {
    from {transform: scale(3.3) rotateX(60deg) translateY(-100%);}
    to {transform: scale(1) rotateX(0deg) translateY(0%);}
}*/
/*END Keyframes*/

/*Top Level Elements*/
::selection {
  background: rgba(9, 63, 59, 0.75);
}

sup {
  vertical-align: super;
  font-size: smaller;
}

html,
body {
  font-family: "Source Sans Pro", sans-serif;
  overflow-y: hidden;
  background: rgb(221, 221, 221);
}

a {
  color: #ff5722;
  text-decoration: underline;
}

b {
  font-weight: bold;
}

em {
  font-style: italic;
}

h2 {
  font-size: 25px;
  margin-bottom: 5px;
}

h3 {
  font-size: 20px;
  margin-bottom: 5px;
}

strong {
  font-weight: bold;
}

/*Container classes and whatnot*/
.container {
  width: 668px;
  margin: 0 auto;
}

.clear {
  clear: both;
  display: block;
}

/*Now here's where the fun begins...*/
.controller {
  position: absolute;
  top: 231px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  margin-top: 30px !important;
  margin-left: 0px !important;
}

.controller.half {
  margin-top: 0px !important;
}

/*BEGIN PS4 Controller Styling*/
.controller.ds4 {
  background: url(/ps4-assets/base.svgz);
  height: 598px;
  width: 806px;
  /*    margin-left: -403px;
        margin-top: -280px;*/
}

.ds4.disconnected {
  background: url(/ps4-assets/disconnected.svgz);
}

.ds4.disconnected div {
  display: none;
}

.ds4 .triggers {
  width: 588px;
  height: 90px;
  position: absolute;
  left: 109px;
}

.ds4 .trigger {
  width: 99px;
  height: 100%;
  background: url(/ps4-assets/triggers.svgz);
  opacity: 0;
}

.ds4 .trigger.pressed {
  opacity: 1;
}

.ds4 .trigger.left {
  float: left;
}

.ds4 .trigger.right {
  float: right;
  background-position-x: 99px;
}

.ds4 .bumper {
  width: 99px;
  height: 23px;
  background: url(/ps4-assets/bumper.svgz) no-repeat;
  opacity: 0;
}

.ds4 .bumpers {
  position: absolute;
  width: 588px;
  height: 23px;
  left: 109px;
  top: 94px;
}

.ds4 .bumper.pressed {
  opacity: 1;
}

.ds4 .bumper.left {
  /* -webkit-transform: rotateY(180deg); */
  /* transform: rotateY(180deg); */
  float: left;
}

.ds4 .bumper.right {
  float: right;
  transform: rotateY(180deg);
}

.ds4 .touchpad {
  width: 262px;
  height: 151px;
  position: absolute;
  left: 272px;
  top: 122px;
  z-index: 20000;
}

.ds4 .touchpad.pressed {
  background: url(/ps4-assets/touchpad.svgz) no-repeat center;
}

.ds4 .meta {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 382px;
  top: 340px;
  z-index: 10000;
}

.ds4 .meta.pressed {
  background: url(/ps4-assets/meta.svgz) no-repeat center;
}

/*Not needed, but I like keeping them here for posterity*/
/*.ds4 .quadrant{
position: absolute;
background: url(/ps4-assets/player-n.svgz);
height: 17px;
width: 111px;
top: 140px;
left: 240px;
}
.ds4 .p0{
background-position: 0 -6px;
}
.ds4 .p1{
background-position: 0 -28px;
}
.ds4 .p2{
background-position: 0 -49px;
}
.ds4 .p3{
background-position: 0 -70px;
}*/
.ds4 .arrows {
  position: absolute;
  width: 352px;
  height: 46px;
  top: 142px;
  left: 227px;
  z-index: 10000;
}

.ds4 .back,
.ds4 .start {
  background: url(/ps4-assets/start.svgz);
  width: 28px;
  height: 46px;
  opacity: 0;
}

.ds4 .back.pressed,
.ds4 .start.pressed {
  /* background-position-y: -21px; */
  /* margin-top: 2px; */
  opacity: 1;
}

.ds4 .back {
  float: left;
  /* width: 28px; */
}

.ds4 .start {
  float: right;
  /* width: 28px; */
  background-position: 28px 0;
}

.ds4 .abxy {
  position: absolute;
  width: 170px;
  height: 171px;
  top: 159px;
  left: 567px;
}

.ds4 .button {
  position: absolute;
  width: 55px;
  height: 55px;
  background: url(/ps4-assets/face.svgz);
}

.ds4 .button.pressed {
  background-position-y: 55px;
  /* margin-top: 5px; */
}

.ds4 .a {
  background-position: 0 0;
  bottom: 0;
  left: 58px;
}

.ds4 .b {
  background-position: -57px 0;
  top: 58px;
  right: 0px;
}

.ds4 .x {
  background-position: -113px 0;
  top: 58px;
}

.ds4 .y {
  background-position: 55px 0;
  left: 58px;
}

.ds4 .sticks {
  position: absolute;
  width: 361px;
  height: 105px;
  top: 308px;
  left: 228px;
}

.ds4 .stick {
  position: absolute;
  background: url(/ps4-assets/sticks.svgz);
  height: 94px;
  width: 94px;
}

.ds4 .stick.pressed.left {
  background-position-x: -96px;
}

.ds4 .stick.pressed.right {
  background-position-x: -192px;
}

.ds4 .stick.left {
  top: 0;
  left: 0;
}

.ds4 .stick.right {
  top: calc(100% - 105px);
  left: calc(100% - 105px);
}

.ds4 .dpad {
  position: absolute;
  width: 125px;
  height: 126px;
  top: 181px;
  left: 92px;
}

.ds4 .face {
  background: url(/ps4-assets/dpad.svgz);
  position: absolute;
}

.ds4 .face.up,
.ds4 .face.down {
  width: 36px;
  height: 52px;
}

.ds4 .face.left,
.ds4 .face.right {
  width: 52px;
  height: 36px;
}

.ds4 .face.up {
  left: 44px;
  top: 0;
  background-position: -37px 0px;
}

.ds4 .face.down {
  left: 44px;
  bottom: 0;
  background-position: 0px 0;
}

.ds4 .face.left {
  top: 45px;
  left: 0;
  background-position: 104px 0;
}

.ds4 .face.right {
  top: 45px;
  right: 0px;
  background-position: 52px 0;
}

.ds4 .face.pressed {
  /* margin-top: 5px; */
  background-position-y: 52px;
}

.ds4.half {
  margin-top: -300px;
}

/*END PS4 Controller Styling*/

/*BEGIN PS4 White Controller Styling*/
.controller.ds4.white {
  background-image: url(/ps4-white-assets/base.svgz);
}

.ds4.white.disconnected {
  background: url(/ps4-assets/disconnected.svgz);
}

.ds4.white .trigger {
  background-image: url(/ps4-white-assets/triggers.svgz);
}

.ds4.white .bumper {
  background-image: url(/ps4-white-assets/bumper.svgz);
}

.ds4.white .touchpad.pressed {
  background-image: url(/ps4-white-assets/touchpad.svgz);
}

.ds4.white .back,
.ds4 .start {
  background-image: url(/ps4-white-assets/start.svgz);
}

.ds4.white .button {
  background-image: url(/ps4-white-assets/face.svgz);
}

.ds4.white .stick {
  background-image: url(/ps4-white-assets/sticks.svgz);
}

.ds4.white .face {
  background-image: url(/ps4-white-assets/dpad.svgz);
}

/*END PS4 White Controller Styling*/

.console {
  padding: 9px;
  vertical-align: top;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 50px;
  border: none;
  font-size: 15px;
  width: 50px;
  color: transparent;
  outline: 0;
  -webkit-filter: invert(0.97);
  -moz-filter: invert(0.97);
  filter: invert(0.97);
  transition: 0.3s ease-out;
  cursor: pointer;
}

.console:hover {
  -webkit-filter: invert(0);
  -moz-filter: invert(0);
  filter: invert(0);
}

.console option {
  color: black;
  background: white;
}

.console.ds4 {
  background: url("/icons/psn.png") no-repeat center;
}

#color-picker {
  display: inline-block;
  width: 50px;
  color: whitesmoke;
  line-height: normal;
  z-index: 30000;
}

#color-picker i {
  font-size: 42px;
  transform: translateY(8%);
}

#color-picker-input {
  transform: scale(0);
}

/*Generic Classes*/
.active {
  display: block;
}

.controller .triggers span,
.controller .bumpers span,
.controller .touchpad,
.controller .meta,
.controller .arrows span,
.controller .abxy span,
.controller .dpad span,
.controller .sticks span {
  cursor: pointer;
  text-decoration: underline;
  -webkit-tap-highlight-color: transparent;
}

